import { toggleModal, populateSelect, removeOptions } from './utility_functions';

export function setupActorSelect(actorTypeId, actorNameId, selectPrompt, onActorTypeSelect) {
  const actorType = document.getElementById(actorTypeId);
  const actorName = document.getElementById(actorNameId);

  document.querySelector(`#${ actorNameId }`).addEventListener('change', function(e) {
    const selectedOption = e.target.options[e.target.selectedIndex]?.value;
    if (selectedOption == 'create_new') {
      if (actorType.options[actorType.selectedIndex].text == 'Blender') {
        toggleModal('blender-modal');
      }
      else if (actorType.options[actorType.selectedIndex].text == 'Farmer') {
        toggleModal('farmer-modal');
      }
      else if (actorType.options[actorType.selectedIndex].text == 'Packer') {
        toggleModal('packer-modal');
      }
      else if (actorType.options[actorType.selectedIndex].text == 'Roaster') {
        toggleModal('roaster-modal');
      }
      else if (actorType.options[actorType.selectedIndex].text == 'Shipper') {
        toggleModal('shipper-modal');
      }
      else if (actorType.options[actorType.selectedIndex].text == 'Warehouse') {
        toggleModal('warehouse-modal');
      }
      else if (actorType.options[actorType.selectedIndex].text == 'Organization') {
        toggleModal('organization-modal');
      }
    }
  });

  document.querySelector(`#${ actorTypeId }`).addEventListener('change', function(e) {
    const actorSelected = e.target.options[e.target.selectedIndex]?.value;

    fetch(`/activity_feeds/autocomplete?klass_name=${ actorSelected }`)
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      removeOptions(actorName);
      populateSelect(actorName, data, selectPrompt, true);
    })
    .catch(error => console.error('Error fetching data:', error)); 
    
    if (onActorTypeSelect) {
      onActorTypeSelect(e);
    }
  });
}
