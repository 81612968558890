export function handleToastClose() {
  const toastContainers = document.querySelectorAll('#toast-container');
  if (toastContainers.length > 0) {
    const toastCloseButtons = document.querySelectorAll('.toast-close');
    toastCloseButtons.forEach(function(toastCloseButton) {
      toastCloseButton.addEventListener('click', function() {
        toastContainers.forEach(function(toastContainer) {
          closeToast(toastContainer);
        });
      });
    });
    toastContainers.forEach(function(toastContainer) {
      fadeOutToast(toastContainer);
    });
  }
}

function fadeOutToast(toastContainer, counterThreshold = 70) {
  let counter = 100; 
  const fadeOutInterval = setInterval(function() {
    counter -= 1; 

    if (counter <= counterThreshold) {
      clearInterval(fadeOutInterval);
      closeToast(toastContainer);
    }
  }, 100); 
}

function closeToast(toastContainer) {
  let opacity = 100; 
  const fadeOutInterval2 = setInterval(function() {
    opacity -= 1;
    toastContainer.style.opacity = opacity / 100;
    if (opacity <= 0) {
      clearInterval(fadeOutInterval2);
      toastContainer.style.display = 'none';
    }
  }, 7);
}