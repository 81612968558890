import SlimSelect from 'slim-select';

export const slimSelect = (target) => {
  new SlimSelect({
    select: target,
  });
}

export const openModalId = () => {
	let activeModalId = '';
	document.querySelectorAll(".modal").forEach(function (ele) {
		if (!ele.classList.contains('pointer-events-none')) {
			activeModalId = ele.id;
		}
	})
	return activeModalId;
}

export const toggleModal = (modalId = null) => {
	const activeModalId = openModalId();
	const body = document.querySelector('body');
	let modal = '';
	if (modalId != null && modalId.length > 0)
		modal = document.getElementById(modalId);
	else
		modal = document.getElementById(activeModalId);

	modal.classList.toggle('opacity-0');
	modal.classList.toggle('pointer-events-none');
	body.classList.toggle('modal-active');
}

export const appendData = (element, text, value) => {
  const selectData = element.slim.getData();
  selectData.push({text: text, value: value});
  element.slim.setData(selectData);
}

export const populateSelectWithArr = (element, data, promptText) => {
  const selectData = [{text: promptText}];
  data.forEach(option => {
    selectData.push({text: option});
  });
  element.slim.setData(selectData);
}
//Create and append the options
export const populateSelect = (element, data, promptText, createOpt) => {
	// Default prompt
	const selectData = [{text: promptText}];

	data.forEach(option => {
    selectData.push({text: option.name, value: option.id});
  });

	// as create 
	if (element.id == 'activity_feed_product_id' && document.getElementById('activity_feed_product_type').value != 'Product')
		createOpt = false
	// last option(as action)
	if (createOpt == true) {
    selectData.push({text: 'CREATE NEW', value: 'create_new'})
	}
  element.slim.setData(selectData);
}

export const removeOptions = (element) => {
  if (!!element.slim) {
    element.slim.setData([]);
  }
}

export const refreshTriggerStates = (promptText) => {
  const taskTypeDivs = document.querySelectorAll(".task-type");
  if (taskTypeDivs.length > 0) {
    taskTypeDivs.forEach((taskTypeDiv) => {
      taskTypeDiv.addEventListener('change', function(e) {
        const taskTypeSelected = e.target.options[e.target.selectedIndex]?.value?.split('-')[1];
        const taskTypeText = e.target.options[e.target.selectedIndex]?.text;
        const eleTrigger = e.target.parentElement.nextElementSibling.children[1];
    
        const models = ['Batch', 'Container', 'Product', 'Variant'] + ['None'];
    
        if (models.includes(taskTypeText)) {
          if (eleTrigger.options.length > 1)
          removeOptions(eleTrigger);
  
          const selectData = [{text: promptText}];
    
          if (taskTypeText != 'None') {
            selectData.push({text: 'create', value: 'create'})
            selectData.push({text: 'update', value: 'update'})
          }
          eleTrigger.slim.setData(selectData);
        }
        else {
    
          fetch(`/task_types/${taskTypeSelected}/actor_status_list`)
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then(data => {
            const statuses = data.status_list;

            if (eleTrigger.options.length > 1) {
              removeOptions(eleTrigger);
            }

            const selectData = [{ text: promptText }];

            statuses.forEach(option => {
              selectData.push({ text: option.text, value: option.id });
            });

            eleTrigger.slim.setData(selectData);
          })
          .catch(error => console.error('Error fetching data:', error));
        }
      })
    });
  }
}

export default { openModalId, toggleModal, populateSelectWithArr, populateSelect, removeOptions, refreshTriggerStates, slimSelect }