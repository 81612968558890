// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from 'jquery';
global.$ = window.$ = window.jQuery = $;
import "@hotwired/turbo-rails"
import { toggleModal, populateSelectWithArr, populateSelect, appendData, removeOptions, refreshTriggerStates, slimSelect } from '../shared/utility_functions';
import "channels";
import { setupActorSelect } from "../shared/select_actor";
import { Application } from "stimulus";
import Carousel from "stimulus-carousel";
import "@fortawesome/fontawesome-free/css/all";
import { getScript, height, showElements, hideElements, toggle, offset, multiSelectValues, addClass, removeClass, documentOn, elementOn } from '../shared/jquery_alternative';
import { handleToastClose } from '../shared/custom_toast'

const application = Application.start();
application.register("carousel", Carousel);

import "cocoon-js-vanilla";

import * as d3 from "d3";

import LocalTime from "local-time";

import "stylesheets/application";
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
LocalTime.start();
Turbo.session.drive = false

document.addEventListener('turbo:load', function() {
  handleToastClose();
  document.querySelectorAll('select').forEach(function(element) {
    if (!element.slim){
      slimSelect(element);
      element.style.display = 'block';
      element.style.opacity = '0';
      element.style.height = '0';
      element.style.width = '0';
      element.style.padding = '0';
      element.style.margin = '0';
    }
  });
});

document.addEventListener('cocoon:after-insert', function (event) {
  document.querySelectorAll('select').forEach(function(element) {
    if (!element.slim){
      slimSelect(element);
      element.style.display = 'block';
      element.style.opacity = '0';
      element.style.height = '0';
      element.style.width = '0';
      element.style.padding = '0';
      element.style.margin = '0';
    }
  });
});


document.addEventListener('DOMContentLoaded', function() {
  const landUseBarGraphContainers = document.querySelectorAll('.land-use-bar-graph');
  if (landUseBarGraphContainers.length > 0) {
    initLandUseGraphs();
  }
  
  const farmFieldGeodataMapContainers = document.querySelectorAll('.geodata-map');
  if (farmFieldGeodataMapContainers.length > 0) {
    initFarmerGeodataMaps(farmFieldGeodataMapContainers);
  }
  
  const donutChart = document.querySelector('#donut-chart');
  if (donutChart) {
    fetch('/business/dashboard_chart')
      .then(response => response.json())
      .then(data => {
        // Create a donut chart
        createDonutChart(data, '#donut-chart', 'Tasks status (overall)');
        createDonutChart(data.weekly, '#donut-chart-weekly', 'Tasks status (last 7 days)');
        createDonutChart(data.monthly, '#donut-chart-monthly', 'Tasks status (last 30 days)');
      })
      .catch(error => console.error('Error fetching data:', error));
  }

  handleToastClose();
  document.querySelectorAll('select').forEach(function(element) {
    if (!element.slim){
      slimSelect(element);
      element.style.display = 'block';
      element.style.opacity = '0';
      element.style.height = '0';
      element.style.width = '0';
      element.style.padding = '0';
      element.style.margin = '0';
    }
  });
  const flashMessage = document.querySelector('.flash-message');
  const sendSmsLink = document.querySelector('.send-sms-link');
  const smsSentText = document.querySelector('.sms-sent-text');
  if (flashMessage && flashMessage?.textContent == 'A pin has been sent to your phone. Please enter it here before it expires in 15 minutes.') {
    sendSmsLink.style.display = 'none';
    smsSentText.style.display = 'block';
    setTimeout(() => {
      smsSentText.style.display = 'none';
      sendSmsLink.style.display = 'block';
    }, 30000);
  }
  // Sidenav slider for mobile devices
  const leftPanelButton = document.querySelector('.left-panel-button');
  const leftPanelContent = document.querySelector('.left-panel');
  const mainLayout = document.querySelector('.main-layout');
  const iterations = 100;
  const intervalTime = 2;

  if (leftPanelButton && leftPanelContent) {
    leftPanelButton.addEventListener('click', function () {
      if (leftPanelButton.style.display == 'block') {
        if (leftPanelContent.style.left != '-100%') {
          let currentIteration = 0;
          let leftValue = '0%';

          let interval = setInterval(function () {
            let currentValue = parseInt(leftValue, 10);
            currentValue -= 1;

            leftValue = currentValue + '%';
            leftPanelContent.style.left = leftValue;

            currentIteration++;
            
            if (currentIteration >= iterations) {
              clearInterval(interval);
              leftPanelContent.style.display = 'none';
              leftPanelButton.style.display = '';
            }
          }, intervalTime);
        }
      }
      else {
        leftPanelButton.style.display = 'block';
        leftPanelContent.style.left = '-100%';
        leftPanelContent.style.display = 'block';
  
        let currentIteration = 0;
        let leftValue = '-100%';
  
        let interval = setInterval(function () {
          let currentValue = parseInt(leftValue, 10);
          currentValue += 1;
  
          leftValue = currentValue + '%';
          leftPanelContent.style.left = leftValue;
  
          currentIteration++;
          
          if (currentIteration >= iterations) {
            clearInterval(interval);
          }
        }, intervalTime);
      }
    });
    mainLayout.addEventListener('click', function () {
      if (leftPanelButton.style.display == 'block') {
        if (leftPanelContent.style.left != '-100%') {
          let currentIteration = 0;
          let leftValue = '0%';

          let interval = setInterval(function () {
            let currentValue = parseInt(leftValue, 10);
            currentValue -= 1;

            leftValue = currentValue + '%';
            leftPanelContent.style.left = leftValue;

            currentIteration++;
            
            if (currentIteration >= iterations) {
              clearInterval(interval);
              leftPanelContent.style.display = 'none';
              leftPanelButton.style.display = '';
            }
          }, intervalTime);
        }
      }
    })
  }
  // Container creation form
  const containerDownArrow = document.getElementById('container-down-arrow');
  const containerUpArrow = document.getElementById('container-up-arrow');

  if (containerDownArrow) {
    containerDownArrow.addEventListener('click', function() {
      containerDownArrow.style.display = 'none';
      containerUpArrow.style.display = 'flex';
      document.querySelectorAll('.dropdown-fields').forEach(function(field) {
        field.style.display = 'flex';
      });
    });
  
    containerUpArrow.addEventListener('click', function() {
      containerUpArrow.style.display = 'none';
      containerDownArrow.style.display = 'flex';
      document.querySelectorAll('.dropdown-fields').forEach(function(field) {
        field.style.display = 'none';
      });
    });
  }

  const priceField = document.getElementById('container_per_kg_price');
  const currencyField = document.getElementById('container_currency');

  const volumeField = document.getElementById('container_volume');
  const unitField = document.getElementById('container_unit');

  if (priceField) {
    priceField.addEventListener('input', function () {
      if (priceField.value) {
        currencyField.required = true;
      } else {
        currencyField.required = false;
      }
    });
  }

  if (volumeField) {
    volumeField.addEventListener('input', function () {
      if (volumeField.value) {
        unitField.required = true;
      } else {
        unitField.required = false;
      }
    });
  }

  // Get all elements with the class "remove-fillable-field" (only available on task_type edit page)
  const removeFieldButtons = document.querySelectorAll(".remove-fillable-field");

  // Add a click event listener to each "remove-fillable-field" button
  if (removeFieldButtons.length > 0) {
    removeFieldButtons.forEach(function(button) {
      button.addEventListener("click", function(event) {
        event.preventDefault();

        // Find the parent "nested-field" element
        const nestedField = button.closest(".nested-fields");

        // Remove the parent "nested-field" from its parent form
        if (nestedField) {
          nestedField.remove();
        }
      });
    });
  };

  const overlay = document.querySelector('.modal-overlay');
  if (typeof (overlay) != 'undefined' && overlay != null) {
    overlay.addEventListener('click', toggleModal);
  }

  const closemodal = document.querySelectorAll('.modal-close');
  for (let i = 0; i < closemodal.length; i++) {
    closemodal[i].addEventListener('click', toggleModal);
  }

  document.onkeydown = function(evt) {
    evt = evt || window.event;
    let isEscape = false;
    if ("key" in evt) {
      isEscape = (evt.key === "Escape" || evt.key === "Esc");
    } else {
      isEscape = (evt.keyCode === 27);
    }
    if (isEscape && document.body.classList.contains('modal-active')) {
      toggleModal();
    }
  };

  const anchors = [].slice.call(document.getElementsByClassName('anchor_class'));
  //TEMP-SOLTUION: this should be managed via css
  // by default first tab selection
  const themeColor = '#829277';
  if (anchors.length > 0) {
    anchors[0].children[0].style['background-color'] = themeColor;
    anchors[0].style.color = themeColor;
  }

  anchors.map((anchor) => {
    anchor.addEventListener('click', function(e) {

      for (let anchor of document.getElementsByClassName('anchor_class')) {
        if (anchor == this) {
          this.children[0].style['background-color'] = themeColor;
          this.style.color = themeColor;
        }
        else {
          anchor.children[0].style['background-color'] = 'black';
          anchor.style.color = 'black';
        }
      }
    });
  });

  // on workflow setup form
  const workflowSelect = document.querySelector(".workflow-select");
  const workflowFields = document.querySelectorAll(".workflow-fields .workflow");

  for (let i = 0; i < workflowSelect?.options?.length; i++) {
    const selectedWorkflow = workflowSelect.options[i].value;
    console.log(selectedWorkflow);
    const workflowId = `workflow-${selectedWorkflow}`;
    workflowFields[i].setAttribute("id", workflowId);

    if (i === 0) {
      workflowFields[i].style.display = "block";
    } else {
      workflowFields[i].style.display = "none";
    }
  }

  if (workflowSelect) {
    workflowSelect.addEventListener("change", function() {
      const selectedWorkflow = this.value;
  
      workflowFields.forEach(function(workflow, index) {
        if (workflow.id === `workflow-${selectedWorkflow}`) {
          workflow.style.display = "block";
        } else {
          workflow.style.display = "none";
        }
      });
    });
  }

  // on activity form
  const actionName = document.getElementById('activity_feed_action');
  const subjectName = document.getElementById('activity_feed_subject_id');
  const productType = document.getElementById('activity_feed_product_type');
  const productName = document.getElementById('activity_feed_product_id');

  // on task form
  const referenceName = document.getElementById('task_reference_id');
  const authorName = document.getElementById('task_author_id');
  const assignedToName = document.getElementById('task_assigned_to_id');

  // on invite form
  const UserActorName = document.getElementById('user_actor_type_ids');
  const UserClientName = document.getElementById('user_client_id');

  // on container form
  const containerCountry = document.querySelector('#container_country');
  if (containerCountry) {
    containerCountry.addEventListener('change', function(e) {
      const countrySelected = e.target.options[e.target.selectedIndex].value;
      const quality_statement_ele = document.getElementById('container_quality_statement');

      const quality_statements = JSON.parse(containerCountry.dataset.qualities)[countrySelected];
      removeOptions(quality_statement_ele);

      if (quality_statements)
        populateSelectWithArr(quality_statement_ele, quality_statements, 'Select a quality statement');
    });
  }

  const activityFeedActorType = document.querySelector('#activity_feed_actor_type');
  if (activityFeedActorType) {
    setupActorSelect('activity_feed_actor_type', 'activity_feed_actor_id', 'Select an Actor', (e) => {
      const actorSelected = e.target.options[e.target.selectedIndex].value;
      fetch(`/activity_feeds/actions_list?klass_name=${ actorSelected }`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        removeOptions(actionName);
        populateSelect(actionName, data.actions, 'Select an Action', false);
      })
      .catch(error => console.error('Error fetching data:', error));
    });

    document.querySelector("#activity_feed_subject_type").addEventListener('change', function(e) {
      const subjectSelected = e.target.options[e.target.selectedIndex]?.value;

      fetch(`/activity_feeds/autocomplete?klass_name=${ subjectSelected }`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        removeOptions(subjectName);
        let prompt = '';
        if (subjectSelected == 'Batch') {
          prompt = 'Select a Batch';
        }
        else if (subjectSelected == 'Container') {
          prompt = 'Select a Container';
        }
        else if (subjectSelected == 'Product') {
          prompt = 'Select a Product';
        }
        else {
          prompt = 'Select a Variant';
        }
        populateSelect(subjectName, data, prompt, false);
      })
      .catch(error => console.error('Error fetching data:', error));
    });

    document.querySelector("#activity_feed_product_id").addEventListener('change', function(e) {
      const selectedOption = e.target.options[e.target.selectedIndex]?.value;
      if (selectedOption == 'create_new') {
        toggleModal('product-modal');
      }
    });
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // on workflow form
  if (document.querySelector('#workflow_name') != null) {

    refreshTriggerStates('Select trigger state');

    document.querySelector('#steps').addEventListener('cocoon:after-insert', function () {
      refreshTriggerStates('Select trigger state');
    });
  }

  // on Workflow setup form
  if (document.querySelector('.assigned-type') != null) {

    document.querySelectorAll(".assigned-type").forEach(function (assignedType) {
      assignedType.addEventListener('change', function(e) {
        const assignedToSelected = e.target.options[e.target.selectedIndex]?.value;
        const eleAssigned = e.target.parentElement.parentElement.nextElementSibling.children[0].children[1];
  
        fetch(`/activity_feeds/autocomplete?klass_name=${ assignedToSelected }`)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          const promptText = 'Select assign to';
  
          if (eleAssigned.options.length > 1)
            removeOptions(eleAssigned);
          // Default prompt
          const selectData = [{text: promptText}];

          data.forEach(option => {
            selectData.push({text: option.name, value: option.id});
          });
          eleAssigned.slim.setData(selectData);
        })
        .catch(error => console.error('Error fetching data:', error));        
      });
    });
  }

  // on task index/show
  if (document.querySelector('.task-index,.task-show') != null) {
    $('.task-index,.task-show').on('click', 'button.task-form', function(e) {
      const status_field = $(this).parents().find('#task_status');
      const new_status = capitalizeFirstLetter($(this).data('status'));
      status_field.val(new_status);
    });

    $('.task-index,.task-show').on('click', '.up', function(e) {
      $(this).addClass('hidden');
      $(this).next().removeClass('hidden');
      $(this).parent().prev().find('.custom-fields').addClass('hidden');
    });

    $('.task-index,.task-show').on('click', '.down', function(e) {
      $(this).addClass('hidden');
      $(this).prev().removeClass('hidden');
      $(this).parent().prev().find('.custom-fields').removeClass('hidden');
    });

    $('.more').on('click', function() {
      $(this).addClass('hidden');
      $(this).next().removeClass('hidden');
      $(this).prev().find('.more-statuses').removeClass('hidden');
    });

    $('.less').on('click', function() {
      $(this).addClass('hidden');
      $(this).prev().removeClass('hidden');
      $(this).prev().prev().find('.more-statuses').addClass('hidden');
    });
  }

  const taskEntryFields = document.querySelector('.task-entry');
  if (taskEntryFields != null) {
    elementOn(document.body, 'click', '.task-entry', function(e) {
      if (e.target.matches('button.task-form')) {
        const status_field = this.querySelector('#task_status');
        const new_status = capitalizeFirstLetter(e.target.dataset.status);
        status_field.value = new_status;
      }
    });
    // Step 1
    document.getElementById('step1').addEventListener('click', function(e) {
      const targetDiv = document.getElementById('step2-info');
      targetDiv.style.display = 'flex';
      targetDiv.scrollIntoView({ behavior: 'smooth' });
      document.getElementById('step2-btn').style.display = 'flex';
    });

    document.getElementById('step1-arrow').addEventListener('click', function(e) {
      if (e.target.classList.contains('fa-chevron-down')){
        e.target.classList.remove('fa-chevron-down');
        e.target.classList.add('fa-chevron-up');
        document.querySelectorAll('.step1-info').forEach(step1Info => {
          step1Info.style.display = 'flex';
        });
      }
      else if (e.target.classList.contains('fa-chevron-up')){
        e.target.classList.remove('fa-chevron-up');
        e.target.classList.add('fa-chevron-down');
        document.querySelectorAll('.step1-info').forEach(step1Info => {
          step1Info.style.display = 'none';
        });
      }
    });

    // Step 2
    document.getElementById('step2').addEventListener('click', function(e) {
      const targetDiv = document.getElementById('step3-info');
      targetDiv.style.display = 'flex';
      targetDiv.scrollIntoView({ behavior: 'smooth' });
      document.getElementById('step3-btns').style.display = 'block';
    });
    
    document.getElementById('step2-arrow').addEventListener('click', function(e) {
      if (e.target.classList.contains('fa-chevron-down')){
        e.target.classList.remove('fa-chevron-down');
        e.target.classList.add('fa-chevron-up');
        document.getElementById('step2-info').style.display = 'flex';
      }
      else if (e.target.classList.contains('fa-chevron-up')){
        e.target.classList.remove('fa-chevron-up');
        e.target.classList.add('fa-chevron-down');
        document.getElementById('step2-info').style.display = 'none';
      }
    });

    // Step 3
    document.getElementById('step3-arrow').addEventListener('click', function(e) {
      if (e.target.classList.contains('fa-chevron-down')){
        e.target.classList.remove('fa-chevron-down');
        e.target.classList.add('fa-chevron-up');
        document.getElementById('step3-info').style.display = 'flex';
      }
      else if (e.target.classList.contains('fa-chevron-up')){
        e.target.classList.remove('fa-chevron-up');
        e.target.classList.add('fa-chevron-down');
        document.getElementById('step3-info').style.display = 'none';
      }
    });
  }

  // on task form
  if (document.querySelector('#task_title') != null) {

    document.querySelector("#task_reference_type").addEventListener('change', function(e) {
      const referenceSelected = e.target.options[e.target.selectedIndex]?.value;

      if (referenceSelected) {
        fetch(`/activity_feeds/autocomplete?klass_name=${ referenceSelected }`)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          removeOptions(referenceName);
          let prompt = '';
          if (referenceSelected == 'Batch') {
            prompt = 'Select a Batch';
          }
          else if (referenceSelected == 'Container') {
            prompt = 'Select a Container';
          }
          else if (referenceSelected == 'Product') {
            prompt = 'Select a Product';
          }
          else {
            prompt = 'Select a Variant';
          }
          populateSelect(referenceName, data, prompt, false);
        })
        .catch(error => console.error('Error fetching data:', error));          
      }
    });

    document.querySelector("#task_assigned_to_type").addEventListener('change', function(e) {
      const assignedToSelected = e.target.options[e.target.selectedIndex]?.value;

      if (assignedToSelected) {
        fetch(`/activity_feeds/autocomplete?klass_name=${ assignedToSelected }`)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          removeOptions(assignedToName);
          const prompt = 'Select assign to';
          populateSelect(assignedToName, data, prompt, false);
        })
        .catch(error => console.error('Error fetching data:', error)); 
      }
    });

    document.querySelector("#task_author_type").addEventListener('change', function(e) {
      const authorSelected = e.target.options[e.target.selectedIndex]?.value;

      if (authorSelected) {
        fetch(`/activity_feeds/autocomplete?klass_name=${ authorSelected }`)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          removeOptions(authorName);
          const prompt = 'Select an author';
          populateSelect(authorName, data, prompt, false);
        })
        .catch(error => console.error('Error fetching data:', error));         
      }
    });
  }

  // on task type form
  if (document.querySelector('#task_type_reference_type') != null) {
    document.querySelector("#task_type_reference_type").addEventListener('change', function(e) {
      if(e.target.value == 'Container') {
        document.getElementById('container_fillable_fields').style.display = 'block';
        document.getElementById('batch_fillable_fields').style.display = '';
      }
      else if(e.target.value == 'Batch') {
        document.getElementById('batch_fillable_fields').style.display = 'block';
        document.getElementById('container_fillable_fields').style.display = '';
      }
      else {
        document.getElementById('batch_fillable_fields').style.display = '';
        document.getElementById('container_fillable_fields').style.display = '';
      }
    });
  }
  // on user invite form
  if (document.querySelector('#user_actor_type_ids') != null) {
    const element = document.querySelector('#user_actor_type_ids');

    fetch(`/actors_autocomplete`)
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      removeOptions(element);
      const prompt = 'Select an author';
      const selectData = [{text: prompt}];
      data.forEach(option => {
        selectData.push({text: `${ option.name } - ${ option.klass_name }`, value: `${ option.klass_name }:${ option.id }`});
      });
      element.slim.setData(selectData);
    })
    .catch(error => console.error('Error fetching data:', error)); 
  }

  // on client invite form
  if (document.querySelector('#user_client_id') != null) {
    const element = document.querySelector('#user_client_id');
    
    fetch(`/actors_autocomplete?c=true`)
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      removeOptions(element);
      const prompt = 'Select an author';
      const selectData = [{text: prompt}];
      data.forEach(option => {
        selectData.push({text: `${ option.name } - ${ option.klass_name }`, value: `${ option.klass_name }:${ option.id }`});
      });
      element.slim.setData(selectData);
    })
    .catch(error => console.error('Error fetching data:', error)); 
  }

  $('.transaction').on('click', '.transaction-trail', function(e) {
    const datasetId = $(this).data('datasetId');

    //TODO: we may not need to send request again as we have already saved info
    // It is good if we need to get further info from response
    if (datasetId) {
      fetch(`/datasets/${ datasetId }`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        document.querySelector('.b-id').textContent = data.dataset_id;
        document.querySelector('.b-hash').textContent = data.data_hash;
        document.querySelector('.b-time').textContent = data.import_time;
        document.querySelector('.b-height').textContent = data.otjson_size_in_bytes;
        document.querySelector('.b-r-hash').textContent = data.root_hash;
        // $('.b-status').text(response.status)
        toggleModal('transaction-modal');
      })
      .catch(error => console.error('Something went wrong! See browser logs\n Error fetching data:', error)); 
    }
    else {
      console.log('Dataset ID is missing for this record!');
      alert('Something went wrong! See browser console logs');
    }
    e.preventDefault();
  });

  $('a#farmer').on('click', function(e) {
    if (document.querySelector('.farmer-address') != null) {
      const latitude = document.querySelector('.farmer-address').dataset.latitude;
      const longitude = document.querySelector('.farmer-address').dataset.longitude;
      initMap('box', { lat: parseInt(latitude), lng: parseInt(longitude) },3);
      // if no activity is there, then this element would not be in the DOM
      if (document.getElementById('left_panel_map') != null) {
        initMap('left_panel_map', { lat: parseInt(latitude), lng: parseInt(longitude) },4);
      }
    }

  });
  $('a#shipping').on('click', function(e) {
    if (document.querySelector('.shipper-address') != null) {
      const latitude = document.querySelector('.shipper-address').dataset.latitude;
      const longitude = document.querySelector('.shipper-address').dataset.longitude;
      initMap('box', { lat: parseInt(latitude), lng: parseInt(longitude) },3);
      // if no activity is there, then this element would not be in the DOM
      if (document.getElementById('left_panel_map') != null) {
        initMap('left_panel_map', { lat: parseInt(latitude), lng: parseInt(longitude) },4);
      }
    }
  });

  $('a#Blending').on('click', function(e) {
    if (document.querySelector('.packer-address') != null) {
      const latitude = document.querySelector('.packer-address').dataset.latitude;
      const longitude = document.querySelector('.packer-address').dataset.longitude;
      initMap('box', { lat: parseInt(latitude), lng: parseInt(longitude) },3);
      // if no activity is there, then this element would not be in the DOM
      if (document.getElementById('left_panel_map') != null) {
        initMap('left_panel_map', { lat: parseInt(latitude), lng: parseInt(longitude) },4);
      }
    }
  });

  $('a#fdc').on('click', function(e) {
    if (document.querySelector('.organization-address') != null) {
      const latitude = document.querySelector('.organization-address').dataset.latitude;
      const longitude = document.querySelector('.organization-address').dataset.longitude;
      initMap('box', { lat: parseInt(latitude), lng: parseInt(longitude) },3);
      // if no activity is there, then this element would not be in the DOM
      if (document.getElementById('left_panel_map') != null) {
        initMap('left_panel_map', { lat: parseInt(latitude), lng: parseInt(longitude) },4);
      }
    }
    $('#fdc').removeClass('hidden');
  });

  $('a#Warehousing').on('click', function(e) {
    if (document.querySelector('.blender-address') != null) {
      const latitude = document.querySelector('.blender-address').dataset.latitude;
      const longitude = document.querySelector('.blender-address').dataset.longitude;
      initMap('box', { lat: parseInt(latitude), lng: parseInt(longitude) },3);
      // if no activity is there, then this element would not be in the DOM
      if (document.getElementById('left_panel_map') != null) {
        initMap('left_panel_map', { lat: parseInt(latitude), lng: parseInt(longitude) },4);
      }
    }
  });

  $('.timeline__event').on('click', '.pfeed-line', function(e) {
    $(this).addClass('hidden');
    $(this).next().removeClass('hidden');
    $(this).next().next().removeClass('hidden');
  });

  $('.timeline__event').on('click', '.mfeed-line', function(e) {
    $(this).addClass('hidden');
    $(this).prev().addClass('hidden');
    $(this).prev().prev().removeClass('hidden');
  });

  if (productType) {
    productType.addEventListener('change', function(e) {
      const productSelected = e.target.options[e.target.selectedIndex]?.value;
  
      fetch(`/activity_feeds/autocomplete?klass_name=${ productSelected }`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        removeOptions(productName);
        let prompt = '';

        if (productSelected == 'Batch') {
          prompt = 'Select a Batch';
        }
        else if (productSelected == 'Product') {
          prompt = 'Select a Product';
        }
        else if (productSelected == 'Container') {
          prompt = 'Select a Container';
        }
        else {
          prompt = 'Select a Variant';
        }
        populateSelect(productName, data, prompt, true);
      })
      .catch(error => console.error('Error fetching data:', error));       
    });
  }

  if (document.querySelector('.profile-edit') != null) {
    document.querySelector('.profile-edit').addEventListener('click', displayForm);

    $('.edit_form').on('click', '.cancel-btn', function(e) {
      e.preventDefault();
      $('.edit_form').addClass('hidden');
      $('.profile-edit').removeClass('hidden');
    });
  }

  $("#blender-btn").on("click", function(event) {
    event.preventDefault();
    create('form#blender');
  });

  $("#farmer-btn").on("click", function(event) {
    event.preventDefault();
    create('form#farmer');
  });

  $("#packer-btn").on("click", function(event) {
    event.preventDefault();
    create('form#packer');
  });

  $("#roaster-btn").on("click", function(event) {
    event.preventDefault();
    create('form#roaster');
  });

  $("#shipper-btn").on("click", function(event) {
    event.preventDefault();
    create('form#shipper');
  });

  $("#warehouse-btn").on("click", function(event) {
    event.preventDefault();
    create('form#warehouse');
  });

  $('#product-btn').on('click', function(event) {
    event.preventDefault();
    create('form#product');
  });

  $('#organization-btn').on('click', function(event) {
    event.preventDefault();
    create('form#organization');
  });

  if (document.getElementById('box') != null) {
    const farmerDirectAddress = JSON.parse(document.querySelector('.coordinates').dataset.farmerDirectAddress);
    initMap('box', { lat: farmerDirectAddress.latitude, lng: farmerDirectAddress.longitude },3);
    // if no activity is there, then this element would not be in the DOM
    if (document.getElementById('left_panel_map') != null) {
      initMap('left_panel_map', { lat: farmerDirectAddress.latitude, lng: farmerDirectAddress.longitude },4);
    }
  }

  $('.input-images').on('change', (e) => {
    let fileNames = [];

    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      fileNames.push(file.name);
    }
    $('.image-names').removeClass('hidden');
    $('.image-names').html(fileNames.join(', '));
  });
});

function initFarmerGeodataMaps(farmFieldGeodataMapContainers) {
  const geodataAccordionIcons = document.querySelectorAll('.geodata-arrows');
  geodataAccordionIcons.forEach((geodataAccordion) => {
    geodataAccordion.addEventListener('click', (e) => {
      if (e.target.classList.contains('fa-chevron-down')){
        e.target.classList.remove('fa-chevron-down');
        e.target.classList.add('fa-chevron-up');
        e.target.parentElement.nextElementSibling.style.display = 'block';
      }
      else if (e.target.classList.contains('fa-chevron-up')){
        e.target.classList.remove('fa-chevron-up');
        e.target.classList.add('fa-chevron-down');
        e.target.parentElement.nextElementSibling.style.display = 'none';
      }
    });
  })
  const geodataValueContainers = document.querySelectorAll('.farm-field-geodata');
  const geodataValues = [];
  let index = 0;
  geodataValueContainers.forEach(geodataValueContainer => {
    geodataValues.push(JSON.parse(geodataValueContainer.textContent));
  })
  farmFieldGeodataMapContainers.forEach(farmFieldGeodataMapContainer => {
    const farmField = JSON.parse(geodataValues[index]);
    index += 1;

    if (Array.isArray(farmField.coordinates[0])){
      farmFieldGeodataMapContainer.style.width = '460px';
      farmFieldGeodataMapContainer.style.height = '340px';
      const map = new google.maps.Map(farmFieldGeodataMapContainer, {
        zoom: 3,
        mapTypeId: "roadmap",
        minZoom: 2
      });
      // Construct the polygon
      let polygonCoordinates = [];
      farmField.coordinates[0].forEach(function(coordinate) {
        polygonCoordinates.push({lat: coordinate[1], lng: coordinate[0]});
      });
      const polygon = new google.maps.Polygon({
        paths: polygonCoordinates,
        strokeColor: '#B2C294',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#B2C294',
        fillOpacity: 0.35
      });
  
      // Add polygon to map
      polygon.setMap(map);
      
      // Fit the map to the polygon bounds
      const bounds = new google.maps.LatLngBounds();
      polygonCoordinates.forEach(function(coordinate) {
        bounds.extend(coordinate);
      });
      map.fitBounds(bounds);
    }
  })
}

function initLandUseGraphs() {
  const landUseAccordionIcons = document.querySelectorAll('.land-use-arrows');
  landUseAccordionIcons.forEach((landUseAccordion) => {
    landUseAccordion.addEventListener('click', (e) => {
      if (e.target.classList.contains('fa-chevron-down')){
        e.target.classList.remove('fa-chevron-down');
        e.target.classList.add('fa-chevron-up');
        e.target.parentElement.nextElementSibling.style.display = 'block';
      }
      else if (e.target.classList.contains('fa-chevron-up')){
        e.target.classList.remove('fa-chevron-up');
        e.target.classList.add('fa-chevron-down');
        e.target.parentElement.nextElementSibling.style.display = 'none';
      }
    });
  })
  const landUseDataContainers = document.querySelectorAll('.land-use-data');
  const landUseDatasets = [];
  landUseDataContainers.forEach(landUseDataContainer => {
    if (landUseDataContainer.textContent != '') {
      landUseDatasets.push(JSON.parse(landUseDataContainer.textContent));
    } else {
      landUseDatasets.push([]);
    }
  })

  const width = 400;
  const height = 200;
  const margin = { top: 20, right: 20, bottom: 30, left: 40 };

  // Create SVG container
  const svgs = d3.selectAll(".land-use-bar-graph");
  let index = 0;
  svgs.each(function() {
    const landUseData = landUseDatasets[index];
    index += 1;

    if (landUseData.length > 0) {
      landUseData.forEach(data => {
        data.key = normalizeLandUseLabels(data.key);
      })
      const svg = d3.select(this)
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom + 90)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);
  
      // Create scales
      const xScale = d3.scaleBand()
        .domain(landUseData.map(d => d.key))
        .range([0, width])
        .padding(0.1);
  
      const yScale = d3.scaleLinear()
        .domain([0, 100])
        .range([height, 0]);
  
      // Create bars
      svg.selectAll("rect")
        .data(landUseData)
        .enter().append("rect")
        .attr("x", d => xScale(d.key))
        .attr("y", d => yScale(d.value))
        .attr("width", xScale.bandwidth())
        .attr("height", d => height - yScale(d.value))
        .attr("fill", "#829277");
  
      // Create x-axis
      svg.append("g")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(xScale))
        .selectAll('.tick text')
        .style('text-anchor', 'end') // Align the tick labels to the end of the tick
        .attr('dy', '0.5em') // Adjust the vertical position of the tick labels
        .attr('transform', 'rotate(-45)'); 
  
      // Create y-axis
      svg.append("g")
        .call(d3.axisLeft(yScale));
    }
  });
}

function normalizeLandUseLabels(str) {
  return str
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

function createDonutChart(data, container, titleText) {
  // Set up the dimensions and radius for the donut chart
  const width = 400;
  const height = 530;
  const radius = Math.min(width, height) / 2;

  const color = d3.scaleOrdinal(['#D1DABC', '#B2C294', '#96AB70', '#788F53', '#5D713F', '#455331', '#3D472D', '#343E29', '#1A2013']);

  const svg = d3.select(container)
    .append('svg')
    .attr('viewBox', `0 0 ${width} ${height}`)
    .attr('preserveAspectRatio', 'none')
    .style('width', '100%')
    .style('height', '100%')
    .append('g')
    .attr('transform', `translate(${width / 2},${height / 2})`);


  // Add title to SVG
  svg.append('text')
    .attr("transform", `translate(0,${-200})`)
    .attr("text-anchor", "middle")  
    .style("font-size", "26px") 
    .style("font-weight", "bold")
    .text(titleText);

  // Create arc function for the donut chart
  const arc = d3.arc()
                .innerRadius(radius - 80)
                .outerRadius(radius - 40);

  // Create pie function
  const pie = d3.pie()
                .value(d => d.value)
                .sort(null);

  // Prepare data
  const donutData = [
    { label: 'Incoming Tasks', value: data.incoming_tasks },
    { label: 'Completed Tasks', value: data.completed_tasks },
  ];

  // Create the donut chart
  svg.selectAll('path')
      .data(pie(donutData))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', d => color(d.data.label))
      .attr('stroke', 'white')
      .style('stroke-width', '2px')
      .each(function (d) {
        // Add text inside each donut section
        const centroid = arc.centroid(d);
        svg.append('text')
          .attr('x', centroid[0])
          .attr('y', centroid[1])
          .attr('dy', '.35em')
          .style('text-anchor', 'middle')
          .text(d.data.value != 0 ? d.data.value : '');
      });

  // Add a legend
  const legend = svg.selectAll('.legend')
                    .data(color.domain())
                    .enter()
                    .append('g')
                    .attr('class', 'legend')
                    .attr('transform', (d, i) => `translate(0,${i * 35})`);

  legend.append('rect')
        .attr('x', width / 2 - 18)
        .attr('y', 160)
        .attr('width', 18)
        .attr('height', 18)
        .style('fill', color);

  legend.append('text')
        .attr('x', width / 2 - 24)
        .attr('y', 170)
        .attr('dy', '.35em')
        .style('text-anchor', 'end')
        .text(d => d);
}

function uploadFile(file, klassName, klassId, customFields) {
  customFields.querySelector('#progress-bar-div').style.display = 'block';
  customFields.querySelector('#file-upload-in-progress').style.display = 'block';
  customFields.querySelector('#file-upload-btns').style.display = 'none';
  const chunkSize = 10485760; // ~10MB in bytes
  let start = 0;
  let i = 0;

  const fileName = file.name.replace(/\s/g, '_');
  const fileKey = `${klassName}/${klassId}/${Date.now()}_${fileName}`.replace(/\.[^.]+$/, '');
  var blob_path = '';
  function processChunk(multipartUpload) {
    let end = start + chunkSize;
    if (end > file.size) {
      end = file.size;
    }

    const chunk = file.slice(start, end);
    const index = (i === 0) ? 'first' : (end >= file.size) ? 'last' : 'mid';
    const partNumber = i + 1;
    const singleFile = (i === 0 && end == file.size) ? true : false;

    return uploadChunk(chunk, start, klassName, klassId, fileKey, index, partNumber, multipartUpload, fileName, singleFile)
      .then((returnedMultipartUpload) => {
        if (returnedMultipartUpload && !returnedMultipartUpload.error) {
          blob_path = returnedMultipartUpload;
          start = end;
          i++;
          
          // Calculate the progress and update the progress bar and text
          const progress = (end / file.size) * 100;
          customFields.querySelector('#progress-bar').style.width = `${progress}%`;
          customFields.querySelector('#progress-text').textContent = `${Math.round(progress)}%`;
          
          if (start < file.size) {
            return processChunk(returnedMultipartUpload); // Recursively process the next chunk
          }
        }
        else {
          const fileUploadFailed = customFields.querySelector('#file-upload-failed')
          customFields.querySelector('#file-upload-in-progress').style.display = '';
          fileUploadFailed.innerHTML = `File Upload Failed.\nError: ${returnedMultipartUpload.error}`
          fileUploadFailed.style.display = 'block';
          console.error('Error:', returnedMultipartUpload.error);
          throw new Error(returnedMultipartUpload.error);
        }
      });
  }

  processChunk(null).then(() => {
    customFields.querySelector('#file-upload-in-progress').style.display = '';
    customFields.querySelector('#file-upload-complete').style.display = 'block';
    const fileContainer = customFields.querySelector('.file-names');

    const fileLink = document.createElement('a');
    fileLink.href = blob_path;
    fileLink.textContent = fileName;
    fileLink.className = 'text-primary font-bold p-2';
    fileLink.target = '_blank';
    const br = document.createElement('br');

    fileContainer.textContent = '';
    fileContainer.appendChild(fileLink);
    fileContainer.appendChild(br);
    // console.log('All chunks processed and sent for upload!');
  });
}

function uploadChunk(chunk, startIndex, klassName, klassId, fileKey, index='mid', partNumber, multipartUpload='', fileName, singleFile) {
  const formData = new FormData();
  formData.append('file', chunk);
  formData.append('start', startIndex);
  formData.append('file_key', fileKey);
  formData.append('index', index);
  formData.append('part_number', partNumber);
  formData.append('file_name', fileName);
  formData.append('single_file', singleFile);
  formData.append('klass_name', klassName);
  formData.append('klass_id', klassId);
  if (multipartUpload) {
    formData.append('multipart_upload', JSON.stringify(multipartUpload));
  }

  return fetch(`/blobs/upload_chunk?timestamp=${Date.now()}`, {
    method: 'POST',
    body: formData
  })
  .then(response => {
    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }
    return response.json();
  })
  .then(data => {
    if (data.multipart_upload) {
      const multipartUpload = data.multipart_upload;
      return multipartUpload;
    }
    return null;
  })
  .catch(error => {
    return { error: error.message }; // Return an error object
  });
}

const fileInputs = document.querySelectorAll('#file-input');

// Event listener for file upload
fileInputs.forEach(fileInput => {
  if (fileInput) {
    fileInput.addEventListener('change', function(event) {
      const customFields = event.target.closest('.custom-fields') || document;
      const removeButton = customFields.querySelector("#file-remove-btn");
      const uploadButton = customFields.querySelector("#file-upload-btn");
      const fileButtons = customFields.querySelector('#file-upload-btns');
      const fileNameField = customFields.querySelector('.file-names');
      if (fileButtons) {
        fileButtons.style.display = 'flex';
      }

      // Event listener for file selection
      if (fileNameField) {
        let fileNames = [];
        for (let i = 0; i < event.target.files.length; i++) {
          const file = event.target.files[i];
          fileNames.push(file.name);
        }
        fileNameField.style.display = 'block';
        fileNameField.innerHTML = fileNames.join(', ');
      }

      // Event listener for file input selection
      if (uploadButton) {  
        uploadButton.addEventListener('click', (event) => {
          const file = fileInput.files[0];
          // if (file.type !== "application/pdf") {
          //   alert("Please select a PDF file.");
          //   return;
          // }

          let klassId;
          let klassName;
          if ($('#task_id').length) {
            klassId = $('#task_id').val();
            klassName = "tasks";
          } else if ($('#farmer_id').length) {
            klassId = $('#farmer_id').val();
            klassName = "geodata";
          }
          uploadFile(file, klassName, klassId, customFields);
        });

        // Secondary validation for file upload
        uploadButton.addEventListener('click', (event) => {
          if (fileInput.files.length > 0) {
            // Do nothing
          } else {
            alert("Please select a file before clicking upload.");
          }
        });
      }

      // Event listener for file input removal
      if (removeButton) {
        removeButton.addEventListener('click', (event) => {
          const fileButtons = customFields.querySelector('#file-upload-btns');
          const progressBarDiv = customFields.querySelector('#progress-bar-div');
          fileInput.value = '';

          fileButtons.style.display = '';
          progressBarDiv.style.display = '';
          fileNameField.textContent = '';
        });
      }
    });
  }
});

const displayForm = () => {
  document.querySelector('.profile-edit').classList.add('hidden');
  document.querySelector('.edit_form').classList.remove('hidden');
};

const create = (formId) => {
  const form = document.querySelector(formId);
  fetch(form.action, {
    method: "POST",
    body: new URLSearchParams(new FormData(form)),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  .then(response => response.json())
  .then(object => {
    if (form.id === 'product') {
      const activtyFeedProductId = document.getElementById('activity_feed_product_id');
      appendData(activtyFeedProductId, object.resource.name, object.resource.id);
    } else {
      const activityFeedActorId = document.getElementById('activity_feed_actor_id');
      appendData(activityFeedActorId, object.resource.name, object.resource.id);
    }
    toggleModal();
  });
};

var currentInfoWindow = null; //using to close info window

const initMap = (elementId, center, zoom) => {
  let coordinates = [];
  let actorDetails = [];
  if (document.querySelector('.coordinates').dataset.coordinates != null) {
    coordinates = JSON.parse(document.querySelector('.coordinates').dataset.coordinates);
    actorDetails = JSON.parse(document.querySelector('.coordinates').dataset.actorDetails);
  }

  const map = new google.maps.Map(document.getElementById(elementId), {
    zoom: zoom,
    center: center,
    mapTypeId: "satellite",
    minZoom: 2
  });

  // Define a symbol using SVG path notation, with an opacity of 1.
  const lineSymbol = {
    path: "M 0,-1 0,1",
    strokeOpacity: 1,
    scale: 4,
  };

  const coffeePathCoordinates = coordinates;
  const coffeePath = new google.maps.Polyline({
    path: coffeePathCoordinates,
    geodesic: true,
    strokeColor: "#688065",
    strokeOpacity: 0,
    icons: [
      {
        icon: lineSymbol,
        offset: "0",
        repeat: "20px",
      }
    ]
  });
  coffeePath.setMap(map);

  coordinates.forEach((coordinate, index) => {
    drawMarker(map, coordinate, actorDetails[index].name, actorDetails[index].description, actorDetails[index].image_url, actorDetails[index].actor_class);
  });

};

const drawMarker = (map, coordinate, title, content, imageUrl, actorClass) => {

  const cont = `<h1>${ title }<i class='text-ex-sm'>(${ actorClass })</i></h1><br>` + content;

  const aboutMarker = {
    client: { let: 'C', col: 'E32083' },
    blender: { let: 'B', col: 'F3C583' },
    farmer: { let: 'F', col: '939B62' },
    organization: { let: 'O', col: 'A5E1AD' },
    packer: { let: 'P', col: '233E8B' },
    roaster: { let: 'R', col: "1EAE98" },
    shipper: { let: 'S', col: 'F9B208' },
    warehouse: { let: 'W', col: 'FF5200' }
  };

  const marker = new google.maps.Marker({
    position: coordinate,
    map,
    title: `${ title }(${ actorClass })`,
    icon: `https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${ aboutMarker[actorClass].let }|${ aboutMarker[actorClass].col }`

    // icon: icon
  });
  marker.addListener("click", () => {

    const infowindow = new google.maps.InfoWindow({
      content: cont,
    });

    infowindow.open({
      anchor: marker,
      map,
      shouldFocus: false,
    });

    //closing current info window
    if (currentInfoWindow != null) {
      currentInfoWindow.close();
    }
    currentInfoWindow = infowindow;
  });
};
//DOM STRINGS OBJECT
const DOM = {
  timelineDate: document.querySelectorAll('.timeline__date'),
  timelineElem: document.querySelectorAll('.timeline__elem'),
  timelineBar: document.querySelector('.timeline__bar')
};

//TIMELINE ELEM SET DIRECTION TO EVENT ITEMS (left or right oriented)

//getting direction from .timeline-elem
const __getDir = timelineElem => {

  if (timelineElem.classList.contains('timeline__elem--left')) {
    return 'left';
  } else if (timelineElem.classList.contains('timeline__elem--right')) {
    return 'right';
  }

};

const setDirEvent = () => {

  DOM.timelineElem.forEach(elem => {

    const direction = __getDir(elem);

    const timelineEvent = elem.querySelector('.timeline__event');

    timelineEvent.classList.add(`timeline__event--${ direction }`);

  });

};

//TIMELINE ELEM DATE STYLES (background)
const __getBGImage = () => {

  // const compStyle = getComputedStyle(DOM.timelineBar);

  //return compStyle.backgroundImage;

};

// const __getBGSize_height = () => {

//   const timebarHeight = DOM.timelineBar.offsetHeight;

//   return timebarHeight;

// }

const __getBGPos_y = (dateElem) => {

  const timelinebarBox = DOM.timelineBar.getBoundingClientRect();

  const dateBox = dateElem.getBoundingClientRect();

  const pos_y = dateBox.top - timelinebarBox.top;

  return pos_y;

};

const setDateBG = () => {

  const bgImg = __getBGImage();

  //const bgHeight = __getBGSize_height();

  DOM.timelineDate.forEach(elem => {

    //setting bgImage
    elem.style.backgroundImage = bgImg;

    //setting bgSize
    elem.style.backgroundSize = `100%`;

    //setting bgPosition
    const dateOffset = __getBGPos_y(elem);

    elem.style.backgroundPosition = `0 -${ dateOffset }px`;

  });

};

//ONLOAD FUNCTION
window.addEventListener('load', () => {

  //setting direction class to the timeline event block
  setDirEvent();

  //set date background styles
  setDateBG();

});




import "controllers";
